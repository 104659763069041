import React, { useState, useLayoutEffect, useCallback } from 'react';
import { Box, Modal, Flex, Button, Para, Label } from 'workspace-core-ui';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useAppDispatch } from '@hooks/redux-hooks';
import useTranslation from '@hooks/useTranslation';
import Input from 'workspace-core-ui/Input';
import { logItem } from '@slices/loggingSlice';
import { addNewNotification } from '@slices/gameStateSlice';
import { motion } from 'framer-motion';
import useVisualViewport from '@hooks/useVisualViewport';

const isInViewport = (
  rect: DOMRect | undefined,
  vHeight: number,
  vWidth: number,
): boolean => {
  if (rect) {
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= vHeight &&
      rect.right <= vWidth
    );
  }

  return true;
};

interface SubmitBetterInfoModalButtonProps {
  questionName: string;
  buttonProps?: any;
}

const SubmitBetterInfoModalButton = (
  props: SubmitBetterInfoModalButtonProps,
) => {
  // we are getting viewport as it dynamically changes based on whether user keyboard is open
  const viewportSize = useVisualViewport();
  const [sourceUrl, setSourceUrl] = useState('');
  const [sourceUrlRect, setSourceUrlRect] = useState<DOMRect>();
  const [commentsRect, setCommentsRect] = useState<DOMRect>();
  const [comments, setComments] = useState('');
  const [sourceUrlFocused, setSourceUrlFocused] = useState(() => false);
  const [shouldElevateSourceUrl, setShouldElevateSourceUrl] = useState(
    () => false,
  );
  const [commentsFocused, setCommentsFocused] = useState(() => false);
  const [shouldElevateComments, setShouldElevateComments] = useState(
    () => false,
  );
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { buttonProps, questionName } = props;
  const overlayState = useOverlayTriggerState({});

  useLayoutEffect(() => {
    // this is so we can measure, round about, if a mobile keyboard is restricting visibility of our modal in its entirety
    const vHeight = viewportSize?.height;
    const vWidth = viewportSize?.width;

    if (vHeight && vWidth) {
      // if our input element is being masked, then something is squeezing the height, most likely a keyboard
      const canSeeSourceUrl = isInViewport(sourceUrlRect, vHeight, vWidth);
      const canSeeComments = isInViewport(commentsRect, vHeight, vWidth);
      setShouldElevateSourceUrl(!canSeeSourceUrl && sourceUrlFocused);
      setShouldElevateComments(!canSeeComments && commentsFocused);
    }
  }, [
    commentsFocused,
    commentsRect,
    sourceUrlFocused,
    sourceUrlRect,
    viewportSize?.height,
    viewportSize?.width,
  ]);

  const sourceUrlMeasure = useCallback(node => {
    if (node !== null) {
      setSourceUrlRect(node?.getBoundingClientRect());
    }
  }, []);

  const commentsMeasure = useCallback(node => {
    if (node !== null) {
      setCommentsRect(node?.getBoundingClientRect());
    }
  }, []);

  return (
    <>
      <Button
        {...buttonProps}
        my={4}
        onPress={() => {
          overlayState.open();
        }}
        buttonSize="medium"
        variant="secondary"
      >
        <Label variant="l3">{t('Better Info')}</Label>
      </Button>
      <Modal
        title={t('Better Info Modal Header')}
        isOpen={overlayState.isOpen}
        onClose={overlayState.close}
        isDismissable
        shouldAutoFocus={false}
        showClose
        closeLabel={t('Close')}
      >
        <Flex
          maxWidth="600px"
          mt={[3, 3, 5]}
          as="form"
          flexDirection="column"
          justifyContent="center"
          onSubmit={e => {
            e.preventDefault();
            // Log when you send correction
            dispatch(
              logItem({
                question_name: questionName,
                collection_name: 'corrections',
                source_url: sourceUrl,
                comments,
              }),
            );
            dispatch(addNewNotification(`Better info sent!`));
            overlayState.close();
            setSourceUrl('');
            setComments('');
          }}
        >
          <Flex order={1} as={motion.div} layout>
            <Para mb={6} variant="p3">
              {t('Better Info Modal Body')}
            </Para>
          </Flex>

          <Flex order={shouldElevateSourceUrl ? 0 : 1} as={motion.div} layout>
            <Box width="100%" ref={sourceUrlMeasure}>
              <Input
                autoComplete="off"
                flex={1}
                onFocus={() => {
                  setSourceUrlFocused(true);
                }}
                onBlur={() => {
                  setSourceUrlFocused(false);
                }}
                onInput={e => {
                  setSourceUrl(e.target.value);
                }}
                label={t('Source Url Input Label')}
                placeholder={t('Source Url Placeholder')}
              />
            </Box>
          </Flex>
          <Flex order={shouldElevateComments ? 0 : 1} as={motion.div} layout>
            <Box width="100%" ref={commentsMeasure}>
              <Input
                autoComplete="off"
                flex={1}
                onFocus={() => {
                  setCommentsFocused(true);
                }}
                onBlur={() => {
                  setCommentsFocused(false);
                }}
                onInput={e => {
                  setComments(e.target.value);
                }}
                as="textarea"
                mt={4}
                label={t('Comments Input Label')}
                placeholder={t('Comments Placeholder')}
              />
            </Box>
          </Flex>
          <Flex order={1} justifyContent="center">
            <Button
              type="submit"
              mt={4}
              buttonSize="medium"
              isDisabled={sourceUrl.length === 0 || comments.length === 0}
            >
              {t('Submit Button')}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default SubmitBetterInfoModalButton;
