import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import {
  inferNextRoute,
  insertFollowupScreen,
  insertRecallScreen,
} from '@slices/routeSlice';
import {
  questionHasBeenAnswered,
  userProgressedToNextClaim,
} from '@slices/levellingSlice';
import ResultsView from '@containers/ResultsView';
import { Display } from 'workspace-core-ui';
import Layout from '@containers/Layout';
import useTranslation from '@hooks/useTranslation';
import BodyWrapper from '@components/BodyWrapper';
import { motion } from 'framer-motion';
import { setHeaderType } from '@slices/gameStateSlice';
import useSound from '@hooks/useSound';
import { logItem } from '@slices/loggingSlice';
import BackgroundCircle, { BallAnimKeys } from '@components/BackgroundCircle';
import { ControlResponse } from '@components/controls/ControlCenter';
import { Claim, Content, ControlData } from 'types';
import useCustomReducedMotion from '@hooks/useCustomReducedMotion';
import Seo from '@containers/Seo';
import useNavigateLog from '@hooks/useNavigateLog';
import ClaimView from '@containers/ClaimView';

// controls for how long you see the splash of "correct!" etc after you answer a claim
const ANSWER_SPLASH_TIMEOUT = 1000;
interface Props {
  /** a completely independent prop used only for testing (we do no want anims affecting tests) */
  shouldAnimate?: boolean;
  pageContext: {
    url: string;
    isRecall?: boolean;
    screenData: Claim<Content, ControlData<Content[]>>;
  };
}

const spring = {
  type: 'spring',
  damping: 10,
  stiffness: 500,
};

// a splash screen shown after you submit an answer
// it just shows you whether you were right or not, for a period of time determined by a constant
const AnswerSplashView = ({
  splashText,
  userAnsweredCorrectly,
}: {
  splashText: string;
  userAnsweredCorrectly: boolean;
}) => {
  const shouldReduceMotion = useCustomReducedMotion();
  const rotationAngle = userAnsweredCorrectly ? -8 : 8;
  return (
    <motion.div
      initial={{
        scale: shouldReduceMotion ? 1 : 0.5,
        rotate: shouldReduceMotion ? rotationAngle : 0,
      }}
      transition={spring}
      animate={{ scale: 1, rotate: rotationAngle }}
    >
      <Display variant="d2" pt={5}>
        {splashText}
      </Display>
    </motion.div>
  );
};

const ClaimPage = (props: Props) => {
  const { t, g } = useTranslation();
  // to prevent spam clicking on btn action
  const [isNavigating, setIsNavigating] = useState(false);
  const { playSound } = useSound();
  const { nextRoute, recallRouteInserted, allFollowupsInserted } =
    useAppSelector(state => state.route);
  const dispatch = useAppDispatch();
  const [startTimestamp, setStartTimestamp] = useState<number>();

  const {
    Claim_Text,
    Answer,
    Correct_Text,
    Incorrect_Text,
    Read_More,
    Controls,
    Sources,
    Gives_Points,
  } = props.pageContext.screenData;
  const { url, isRecall } = props.pageContext;

  const [ballAnim, setBallAnim] = useState<BallAnimKeys>('bounceFromTop');
  const [submittedAnswer, setSubmittedAnswer] =
    useState<ControlResponse | undefined>(undefined);
  // we use strings instead of boolean, because on Airtable the possible answer is chosen from a string base dropdown (airtable does not support primitives in api directly)
  const userAnsweredCorrectly =
    submittedAnswer?.controlValue.toString() === Answer;
  const [currentViewName, setCurrentViewName] =
    useState<'claim' | 'answerSplash' | 'resultsCorrect' | 'resultsIncorrect'>(
      'claim',
    );

  const heading = isRecall
    ? t('Recall Header')
    : Controls && g(Controls.Header_Text);
  const backgroundColor = isRecall ? 'primary' : 'background';

  const questionName = Claim_Text?.Content_Type;

  useNavigateLog({ questionName });

  useLayoutEffect(() => {
    // record when user officially "sees" the question
    setStartTimestamp(Date.now());
  }, []);

  useEffect(() => {
    // set our header here accordingly
    if (isRecall) {
      dispatch(setHeaderType({ headerType: 'recall' }));
    } else {
      dispatch(setHeaderType({ headerType: 'full' }));
    }
    // based on our current route, figure out what user needs to see next on mount
    dispatch(inferNextRoute({ compareAgainst: url }));
  }, [dispatch, isRecall, url]);

  // this is called after you answer, on the results screen
  const goToNextScreen = () => {
    playSound('Button');
    // mark as progress, only if user is not on a recall (since recalls are not really new claim)
    if (!isNavigating) {
      dispatch(
        userProgressedToNextClaim({
          isRecall: !!isRecall,
        }),
      );
    }
    setIsNavigating(true);
  };

  useEffect(() => {
    let timerId = 0;
    if (submittedAnswer === undefined) {
      setCurrentViewName('claim');
    } else {
      // therefore it cannot confer any points
      dispatch(
        questionHasBeenAnswered({
          userAnsweredCorrectly,
          givesPoints: !!Gives_Points,
        }),
      );
      // Log the result of the claim answered
      const endTimestamp = Date.now();
      dispatch(
        logItem({
          question_name: questionName,
          question_type:
            `${Controls?.Control_Type}${isRecall ? '-recall' : ''}` || '',
          collection_name: 'answers',
          // cast our chosen answer to a number, to index string
          // TODO: this only works for bools, so in case claims ever use diff types of controls; we'd need to offload this logic to the setstate
          answer_text: submittedAnswer.controlValue.toString(),
          // chosen_answer_value: userAnsweredCorrectly,
          duration_in_seconds: startTimestamp
            ? (endTimestamp - startTimestamp) / 1000
            : 0,
          result: userAnsweredCorrectly ? 'correct' : 'incorrect',
        }),
      );
      setCurrentViewName('answerSplash');
      setBallAnim('blowUp');
      if (userAnsweredCorrectly) {
        playSound('Correct');
      } else {
        playSound('Incorrect');
      }
      timerId = setTimeout(() => {
        // setBallAnim('blowUp');
        // After a few seconds show the results view
        if (userAnsweredCorrectly) {
          setCurrentViewName('resultsCorrect');
        } else {
          setCurrentViewName('resultsIncorrect');
          // if user answered incorrectly, we line up a recall in our routing list (only if its there and not already one lined up)
          // at most, we only have a single recall route inserted into the list
          // TODO: now, this might/most likely will change in the future
          if (!recallRouteInserted) {
            dispatch(insertRecallScreen({ url }));
          }
          // similarly, we insert followups to the claim if there are still followups left
          // we do not want to have a followup to a recall, because this creates an impossible url
          // something like, claim-4-recall-followup
          // this route was never pregenerated, and most likely will not be in near future
          if (!allFollowupsInserted && !isRecall) {
            dispatch(insertFollowupScreen({ url }));
          }
          /** infer the next route again, why? Ok so if the next route inferred was 'foo' in a list of ['cow', 'bias', 'foo', 'end']
           *  inserting a recall/followup (let's say, "bias-recall") at potentially the current spot at 'foo'
           * (if we set the recall to immediately show up after an incorrect answer)
           * thought really, no one will make recall deltas so small, so really and edge case and more applicable to followups
           *  would actually still point us to "foo" and not "bias-recall", as we only infer the next route at mount */
          dispatch(inferNextRoute({ compareAgainst: url }));
        }
      }, ANSWER_SPLASH_TIMEOUT);
    }
    return () => {
      clearTimeout(timerId);
    };
    // TODO: investigate why this was necessary (infinite loop mb)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isRecall, submittedAnswer, url, userAnsweredCorrectly]);

  const resultsSharedProps = {
    goToNextScreen,
    to: nextRoute?.url || '/',
    // this is optional and renders only when there
    readMore: Read_More?.length > 0 && g(Read_More, true),
    sources: g(Sources, true),
    gradientFadeThemeColor: isRecall ? 'primary' : 'secondary',
    url,
  } as const;

  const View = {
    // our 'default' view
    claim: (
      <ClaimView
        headerText={heading}
        claimTextMdx={g(Claim_Text, true)}
        setSubmittedAnswer={setSubmittedAnswer}
        typeOfControl={Controls?.Control_Type}
        possibleAnswers={Controls?.Possible_Answers}
      />
    ),
    // got it right
    resultsCorrect: (
      <ResultsView
        questionName={questionName}
        heading={t('Correct Result Header')}
        blurb={Correct_Text}
        {...resultsSharedProps}
      />
    ),
    // got it wrong
    resultsIncorrect: (
      <ResultsView
        questionName={questionName}
        heading={t('Incorrect Result Header')}
        blurb={Incorrect_Text}
        {...resultsSharedProps}
      />
    ),
    answerSplash: (
      <AnswerSplashView
        userAnsweredCorrectly={userAnsweredCorrectly}
        splashText={
          userAnsweredCorrectly ? t('Correct Splash') : t('Incorrect Splash')
        }
      />
    ),
  };

  return (
    <Layout
      // this is mainly for testing purposes
      shouldAnimate={props?.shouldAnimate}
      backgroundColor={backgroundColor}
    >
      {/* TODO: still need to figure this one out in terms of social share */}
      <Seo description={`Did you know:${g(Read_More, false)}`} />
      {!isRecall && (
        <BackgroundCircle
          sx={{
            backgroundColor: 'secondary',
          }}
          whichAnim={ballAnim}
        />
      )}
      <BodyWrapper p={5}>{View[currentViewName]}</BodyWrapper>
    </Layout>
  );
};

export default ClaimPage;
