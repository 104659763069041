import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { Box } from 'workspace-core-ui';
import css from '@styled-system/css';
import useCustomReducedMotion from '@hooks/useCustomReducedMotion';

// TESTING
function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

// TODO: refine this...all of this
const Circle = motion(
  styled(Box)(
    css({
      pb: '100%',
      height: 'auto',
      width: '100%',
      position: 'fixed',
      borderRadius: '50%',
      left: 0,
      right: 0,
      bottom: 0,
      top: [
        'calc(100vh - 30vmax + 55vmin)',
        'calc(100vh - 30vmax + 55vmin)',
        'calc(100vh - 35vmin + 55vmax)',
      ],
      margin: 0,
    }),
  ),
);

const ballAnims = {
  bounceFromTop: {
    scale: 1.5,
    y: '-27%',
    delay: 0.2,
    type: 'spring',
    bounce: getRandomArbitrary(0.5, 0.75),
  },
  shrinkToCenter: {
    transition: {
      delay: 0,
      type: 'tween',
      damping: 200,
      stiffness: 300,
      bounce: getRandomArbitrary(0.5, 0.75),
    },
    top: 0,
  },
  blowUp: {
    scale: 4.5,
    transition: {
      type: 'spring',
      bounce: getRandomArbitrary(0.5, 0.75),
      duration: 2.5,
    },
  },
  fade: {
    delay: 0.2,
    opacity: 1,
  },
};

export type BallAnimKeys = keyof typeof ballAnims;

const BackgroundCircle = ({
  sx,
  whichAnim = 'bounceFromTop',
  ...props
}: {
  sx: object;
  whichAnim: BallAnimKeys;
}) => {
  const shouldReduceMotion = useCustomReducedMotion();
  return (
    <Circle
      variants={ballAnims}
      animate={shouldReduceMotion ? '' : whichAnim}
      sx={{
        backgroundColor: 'primaryDark',
        ...(shouldReduceMotion
          ? {
              transform: 'translateY(-27%) scale(1.5)',
            }
          : {}),
        ...sx,
      }}
      {...props}
    />
  );
};

export default BackgroundCircle;
