import React from 'react';
import { Heading, Flex, Para, Box } from 'workspace-core-ui';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import useSound from '@hooks/useSound';
import ControlCenter, {
  ControlCenterProps,
} from '@components/controls/ControlCenter';

export interface ClaimViewProps extends ControlCenterProps {
  headerText: string;
  claimTextMdx: string;
}

const ClaimView = ({
  headerText,
  claimTextMdx,
  /** callback that is triggered once our control is interacted with */
  setSubmittedAnswer,
  typeOfControl,
  possibleAnswers,
}: ClaimViewProps) => {
  const { playSound } = useSound();
  return (
    <>
      <Flex flex={0.7} />
      <Flex
        flexDirection="column"
        flex={0.7}
        alignItems="center"
        maxWidth="1100px"
        alignSelf="center"
      >
        <Box p={5}>
          <Heading variant="h2" mb={4} data-testid="claim-heading">
            {headerText}
          </Heading>
          <Para as="span" variant="claimText" maxWidth="1100px">
            <CustomMdxRenderer>{claimTextMdx}</CustomMdxRenderer>
          </Para>
        </Box>
      </Flex>
      {/* NOTE: for now, assume our answers will always be in the binary true false */}
      <Flex flex={0.3}>
        {typeOfControl && (
          <ControlCenter
            setSubmittedAnswer={e => {
              playSound('Button');
              setSubmittedAnswer(e);
            }}
            typeOfControl={typeOfControl}
            possibleAnswers={possibleAnswers}
            appearance="normal"
          />
        )}
      </Flex>
    </>
  );
};

export default ClaimView;
